<template>
  <div>
    <search-form>
      <div class="d-inline-flex flex-wrap mb-3">
        <auto-complete
          v-model="search.id"
          item-text="fullName"
          item-value="id"
          :search="userSearch"
          :label="$t('users.user')"
          search-form
        />
      </div>

      <div class="ma-1">
        <v-btn color="success" dark @click="filtering" small :loading="progressing">
          <v-icon small class="me-1"> mdi-filter </v-icon>
          {{ $t('shared.applyFilter') }}
        </v-btn>
      </div>
    </search-form>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="context"
      @update:options="filtering"
      :server-items-length="count"
      :loading="progressing"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
      class="elevation-2"
    >
      <template #[`item.actions`]="{item}">
        <div class="d-flex justify-end">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="info" :to="`info/${item.id}`" v-on="on">
                <v-icon> mdi-eye </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.edit') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.image`]="{item}">
        <Thumbnail v-if="item.image" :src="item.image" width="40" height="40" />
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    type: {type: String}
  },
  data() {
    return {
      progressing: false,
      count: 1,
      context: {},
      items: [],
      headers: [
        {text: 'ID', value: 'id'},
        {text: this.$t('shared.image'), value: 'image'},
        {text: this.$t('shared.firstName'), value: 'firstName', sortable: true},
        {text: this.$t('shared.lastName'), value: 'lastName'},
        {text: this.$t('shared.mobile'), value: 'mobile'},
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      search: {
        id: null
      }
    }
  },
  methods: {
    userSearch(filter) {
      return this.$api.users.getAllInfo(filter)
    },
    filter(isCount) {
      const filter = this.$filter()

      if (!isCount) {
        filter['sort[0].column'] = this.context.sortBy[0]
        filter['sort[0].type'] = this.context.sortDesc[0] ? 'ASCENDING' : 'DESCENDING'
        filter.size = this.context.itemsPerPage
        filter.page = this.context.page
      }

      return filter
    },
    getItems(filter) {
      return this.$api.health.medicalUsers(filter, this.type).then((res) => {
        this.items = res
      })
    },
    getCount(filter) {
      return this.$api.health.medicalUsersCount(filter, this.type).then((res) => {
        this.count = res
      })
    },
    filtering() {
      this.progressing = true
      Promise.all([this.getItems(this.filter()), this.getCount(this.filter(true))])
        .then((res) => {
          this.progressing = false
        })
        .catch((err) => {
          this.$showError(err)
        })
    },
    async removeItem(item) {
      try {
        if (!(await this.$confirm())) return

        await this.$api.health.delete(item.id)
        this.filtering()
        this.$showSuccess(this.$t('health.realmRemoved'))
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
